<template>
  <div id="user-message-details">
    <div class="user-message">
      <!-- 个人资料 -->
      <!-- <div class="user-header">
        <img src="../../../static/img/userData/user-header-logo.png" alt="" />
        <span>{{ getString(strings.User_Personal_information) }}</span>
      </div> -->

      <div class="user-data">
        <div class="user-data-header">
          <div v-if="userData.isMember" class="user-avatar">
            <img
              v-real-img="userData.avatar"
              src="../../../static/img/userData/avatar_yellow_man.png"
              alt=""
            />
          </div>
          <div v-else class="user-avatar-nomember">
            <img
              v-real-img="userData.avatar"
              src="../../../static/img/userData/avatar_yellow_man.png"
              alt=""
            />
          </div>
          <div class="user-data-edit" @click="getUserMessage">
            <img
              src="../../../static/img/userData/updata-userData_logo.svg"
              alt=""
            />
          </div>
        </div>
        <div class="user-text">
          <div class="user-name">
            {{ userData.username }}
          </div>
          <div class="user-motto">
            {{ userData.motto }}
          </div>
          <div v-if="userData.isMember" class="user-member">
            {{ getString(strings.User_Member_Validity_Period) }}：{{
              userData.memberExpire
            }}
          </div>
        </div>
      </div>

      <!-- 我的分享 -->
      <div class="user-share">
        <MeShareData :initUserData="userDataMyShare" />
      </div>
      <!-- 我的收藏 -->
      <div class="user-share">
        <MeShareCollection :initUserData="userDataMyCollection" />
      </div>
    </div>
  </div>
</template>

<script>
import MeShareData from "./meMessageData/MeShareData";
import MeShareCollection from "./meMessageData/MeShareCollection";

import dataTimeToFormats from "../../utils/timestampToTime";
import httpImageToPrefix from "../../utils/httpImageToPrefix";

import strings from "../../common/lang/strings";
import getString from "../../common/lang/language";
import {
  postUserMe,
  postUserMyCollects,
  postUserMyShares,
} from "../../common/netWork/base_api";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      userData: "",
      userDataMyShare: {},
      userDataMyCollection: {},
      strings: strings,
    };
  },
  components: {
    MeShareData,
    MeShareCollection,
  },
  mounted() {
    this.postUserMe();
    this.postUserMyShares();
    this.postUserMyCollects();
  },
  methods: {
    ...mapMutations(["detailsToUserMessageModel"]),
    //
    getString(i) {
      return getString(i);
    },
    //用户资料
    postUserMe() {
      let userData = localStorage.getItem("meMessage");
      if (userData == null || userData == "") {
        return;
      }
      let userId = JSON.parse(userData);
      postUserMe({ userId: userId.userId }, (userData) => {
        this.userData = userData;
        this.userData.avatar = httpImageToPrefix(this.userData.avatar);
        const memberExpire = dataTimeToFormats.timestampToTime(
          this.userData.memberExpire
        );
        let userMemberIsPermanent =
          parseInt(userData.memberExpire - userData.serverTime) / 86400;//获取会员天数
        if (userMemberIsPermanent >= 365*30) { //会员天数大于30年则是永久会员
          this.userData.memberExpire = getString(strings.Permanent_Member);
        } else {
          this.userData.memberExpire = memberExpire;
        }
      });
    },
    //分享资料
    postUserMyShares() {
      postUserMyShares({ lastId: "" }, (res) => {
        this.userDataMyShare = res;
      });
    },
    //收藏资料
    postUserMyCollects() {
      postUserMyCollects(null, (res) => {
        this.userDataMyCollection = res;
      });
    },
    //修改用户资料
    getUserMessage() {
      this.detailsToUserMessageModel(true);
    },
  },
  watch:{
    "$store.state.userMessageModel"(newModelAccout) {
      this.postUserMe();
    },
  }
  // activated() {
  //   //使用缓存，直接跳过
  //   return;
  // },
};
</script>

<style lang="less" scoped>
#user-message-details {
  width: 100%;
  background: #FBFBFB;
  padding: 20px;
  // margin: 40px 40px 0px 40px;
  .user-message {
    margin-right: 0px;
    overflow: hidden;
    .user-header {
      background: #f0f2f8;
      height: 48px;
      display: flex;
      align-items: center;
      img {
        margin: 20px 10px 20px 20px;
      }
      span {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 14px;
        color: #666666;
        opacity: 1;
      }
      .user-share-more {
        position: absolute;
        right: 27px;
        display: flex;
        align-items: center;
        cursor: pointer;
        overflow: hidden;
        span {
          transition: 0.3s;
        }
      }
      .user-share-more:hover {
        // span {
        //   color: #fd492b;
        //   transition: 0.3s;
        // }
        // img {
        //   width: 14px;
        //   height: 15px;
        //   filter: drop-shadow(#fd492b 100px 0);
        //   transform: translateX(-100px);
        //   overflow: hidden;
        // }
      }
    }
    .user-data {
      // margin: 20px 0 0px 0;
      display: flex;
      align-items: flex-end;
      background: #FFFFFF;
      padding: 20px;
      .user-data-header {
        position: relative;
        .user-avatar {
          display: flex;
          justify-content: center;
          background-image: url(../../../static/img/userData/user_message_vip_bg.svg);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
          width: 90px;
          height: 119px;
          position: relative;
          img {
            width: 86px;
            height: 86px;
            border-radius: 50%;
            position: absolute;
            bottom: 2px;
          }
        }
        .user-avatar-nomember {
          display: flex;
          justify-content: center;
          background-repeat: no-repeat;
          width: 90px;
          height: 86px;
          position: relative;
          img {
            width: 86px;
            height: 86px;
            border-radius: 50%;
            position: absolute;
            bottom: 2px;
          }
        }
        .user-data-message {
          margin-left: 30px;
          .user-name {
            min-width: 200px;
            font-size: 18px;
            font-family: PingFang SC;
            /*font-weight: bold;*/
            line-height: 25px;
            height: 25px;
            color: #333333;
            opacity: 1;
            text-align: center;
            margin-top: 24px;
          }
          .user-message {
            text-align: center;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 25px;
            height: 25px;
            color: #555555;
            opacity: 1;
            overflow: hidden;
          }
        }
        .user-data-edit {
          position: absolute;
          bottom: 1px;
          right: 3px;
          width: 32px;
          height: 32px;
          // background: #ff765e;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
      .user-text {
        margin-left: 30px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        width: 260px;
        height: 86px;
        .user-name {
          min-width: 200px;
          font-size: 18px;
          font-family: PingFang SC;
          /*font-weight: bold;*/
          line-height: 14px;
          color: #333333;
          opacity: 1;
          img {
            margin-left: 20px;
            cursor: pointer;
          }
        }
        .user-motto {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 16px;
          color: #9a9a9a;
          opacity: 1;
          min-width: 200px;
          margin-top: 10px;
          letter-spacing: 1px;
        }
        .user-member {
          width: 100%;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 14px;
          color: #ff765e;
          opacity: 1;
        }
      }
    }
    .user-share {
      margin: 20px 0px;
    }
  }
}
</style>